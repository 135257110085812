<template lang="pug">
  .slots-list(
    v-infinite-scroll="onLoadMore"
    infinite-scroll-disabled="disabledLoadingMore"
    infinite-scroll-distance="150"
  )
    page-date(
      v-for="(item, name, index) in model.list" :date="name" :item="item" :key="index"
    )
    div(class="d-flex align-center fill-height justify-center" v-if="loading")
      v-progress-circular(indeterminate size=64 color="warning")
</template>

<script>
import InfinityScroll from '@/components/global/infinityScroll.vue'
import PageDate from "./SlotsDate.vue"

export default {
  props: {
    model: Object,
    pagination: Object,
    loading: Boolean
  },

  data: () => ({
    busy: true
  }),

  computed: {
    disabledLoadingMore() {
      if (!this.pagination) return true
      if (this.loading) return true
      if (!this.pagination.next) return true
      return false
    },
  },

  methods: {
    onLoadMore() {
      this.$emit('loadMore')
    }
  },

  components: {
    pageDate: PageDate,
    infinityScroll: InfinityScroll
  }
}
</script>

<style lang="scss" scoped>
.slots-list {
  max-width: 600px;
}

.full-height {
  height: 100vh;
}
</style>