<template lang="pug">
  .slots-date
    .slots-date__header
      span.slots-date__title {{ getFullDateDutch }}
    location(
      v-for="(slotItem, name, index) in item"
      :location="name"
      :key="index"
      :items="slotItem"
      :fullDate="getFullDateDutch"
    )
</template>

<script>
import { dateFormating, getDOWDutch } from '@/util'

export default {
  props: {
    item: Object,
    date: String
  },

  computed: {
    formattedDate() {
      return dateFormating(this.date).join('-')
    },

    getFullDateDutch() {
      let DOW = getDOWDutch(new Date(this.formattedDate).getDay())
      return `${ this.date } ${ DOW }`
    }
  },

  components: {
    location: () => import('./SlotsLocation.vue')
  }
}
</script>

<style lang="scss" scoped>
.slots-date {
  margin: 0 16px;
  border: 1px solid $border-color;

  &__header {
    background-color: #E5E5E5;
    padding: 16px 8px;
  }

  &__title {
    font-weight: 500;
  }
}
</style>